html body {
	// .Polaris-Page.Polaris-Page--fullWidth {
	// 	padding-top: 1.25rem !important;
	// }

	.Polaris-DataTable {
		background-color: var(--main2);
	}

	.Polaris-TopBar {
		box-shadow: none !important;
	}

	.Polaris-Button.Polaris-Button--outline.Polaris-Button--iconOnly:not(.Polaris-Button--primary):not(.Polaris-Button--destructive) {
		background: transparent !important;
		border-color: transparent !important;

		&:hover {
			background: var(--main3) !important;
		}
	}

	.datatable tbody th,
	.datatable tbody td {
		border-color: var(--borderColor) !important;
	}

	.Polaris-Checkbox__Backdrop {
		background-color: var(--textColor);
	}

	@media (min-width: 48.0625em) {
		.Polaris-Navigation__PrimaryNavigation {
			padding-top: 0;
		}
	}

	.menu-items .Polaris-Navigation__Item--selected::before {
		display: none;
	}

	.Polaris-ActionList__Item {
		border: none;
	}

	.Polaris-TopBar-SearchField__Input:focus ~ .Polaris-TopBar-SearchField__Backdrop::after {
		box-shadow: none !important;
	}
	.Polaris-TopBar-SearchField__Input {
		&::placeholder {
			color: var(--textColor2);
		}
	}
	.Polaris-Badge--statusNew {
		background-color: var(--textColor);
		color: var(--main1);
	}

	[class^="Polaris-Skeleton"]::after {
		background-color: var(--main3);
	}
	.Polaris-Page-Header__Row .Polaris-ActionMenu-SecondaryAction {
		.Polaris-Button {
			opacity: 0.75;

			&:hover {
				opacity: 1;
			}
		}
	}

	.calendar_modal {
		.Polaris-TextField--focus > .Polaris-TextField__Input ~ .Polaris-TextField__Backdrop::after,
		.Polaris-TextField__Input:focus ~ .Polaris-TextField__Backdrop::after {
			box-shadow: none;
		}
	}
	button:not(.Polaris-ActionList--destructive):not(.Polaris-ActionList--disabled) .Polaris-ActionList__Text {
		color: var(--textColor);
	}

	html body {
		.Polaris-EmptyState--withinPage .Polaris-EmptyState__ImageContainer .Polaris-EmptyState__Image,
		img[src*="/assets/images/empty_state"] {
			max-height: 60vh;
			width: 100%;
			margin: auto;
		}

		.big_data .Polaris-EmptyState--withinPage .Polaris-EmptyState__ImageContainer .Polaris-EmptyState__Image {
			max-height: 40vh;
		}
	}

	.big_data.datatable .Polaris-ButtonGroup.Polaris-ButtonGroup--segmented {
		justify-content: center;
	}

	.Polaris-TextField--focus > .Polaris-TextField__Input ~ .Polaris-TextField__Backdrop::after,
	.Polaris-TextField__Input:focus ~ .Polaris-TextField__Backdrop::after {
		/* box-shadow: 0 0 0 0.1250rem var(--p-focused); */
		box-shadow: none;
	}

	.Polaris-TextField__Backdrop::after,
	.Polaris-Tag__Button::after,
	.Polaris-DropZone__Container::after
// .Polaris-OptionList-Option__SingleSelectOption::after
	{
		inset: 0;
	}

	//Overflow menu on contact list is not centered without this
	.Polaris-ActionMenu-SecondaryAction .Polaris-Button__Icon {
		margin: auto;
	}

	.Polaris-Avatar__Svg text {
		font-size: 0.9375rem;
	}
	.Polaris-Badge.Polaris-Badge--statusNew {
		background-color: #ffffff;
		color: #000000;
	}

	// .Polaris-ActionList {
	// 	.Polaris-ActionList__Actions {
	// 		padding: 0.1562rem;
	// 	}
	// }

	.Polaris-Page {
		max-width: unset;
	}
	.Polaris-Tooltip-TooltipOverlay__Content {
		max-width: 50vw;
	}

	.tooltip-transparent {
		&.Polaris-Tooltip-TooltipOverlay,
		.Polaris-Tooltip-TooltipOverlay {
			box-shadow: none;
		}

		.Polaris-Tooltip-TooltipOverlay__Content {
			background: transparent;
			box-shadow: none;
		}
	}

	.Polaris-TopBar-SearchField__Backdrop {
		border-radius: 100px;
	}

	// .Polaris-Page-Header__TitleWrapper {
	// 	flex-grow: 0;
	// }

	.Polaris-Checkbox__Backdrop {
		background-color: var(--textColor);
	}

	.Polaris-Checkbox__Input:disabled + .Polaris-Checkbox__Backdrop {
		background-color: var(--p-surface);
	}

	.Polaris-Frame__Content {
		height: 100%;
	}

	.Polaris-Backdrop.Polaris-Backdrop--transparent {
		background-color: var(--p-backdrop);
	}

	.Polaris-Tabs__Tab:focus:not(:active) {
		.Polaris-Tabs__Title {
			font-weight: bold;
		}

		.Polaris-Tabs__Title::after {
			box-shadow: none;
		}
	}

	.Polaris-Tag__Button {
		border-radius: 0.4375rem;
	}

	.Polaris-Tag.Polaris-Tag--removable {
		--p-icon: var(--main1);

		.Polaris-Tag__Button::after {
			border-radius: 0.4375rem;
			box-shadow: none;
		}
	}

	.Polaris-EmptyState__Section {
		max-height: 80vh;

		img[src*="/assets/images/empty_state"] {
			max-height: 50vh;
		}

		.Polaris-EmptyState__ImageContainer {
			height: 100%;

			img {
				max-height: 50vh;
			}
		}
	}

	.Polaris-Navigation__Item.Polaris-Navigation__RollupToggle:focus::after {
		box-shadow: none;
	}

	.Polaris-Page .Polaris-Page-Header {
		.Polaris-Page-Header__Row {
			justify-content: start;
		}
	}

	.Polaris-FormLayout__Item > .Polaris-FormLayout {
		margin-top: 0;
	}
}

.Polaris-Checkbox__Input:not([aria-checked="true"]) {
	.Polaris-Checkbox__Backdrop::before {
		background-color: var(--textColor);
	}
}
.Polaris-Page.Polaris-Page--fullWidth {
	width: 100%;
}

.Polaris-DataTable__Heading:focus:not(:active) {
	// & > span:not([class="Polaris-DataTable__Icon"]) {
	// 	box-shadow: 0 0 0 0.1250rem var(--p-focused);
	// }

	&::after {
		box-shadow: none;
		outline: 0.0625rem solid transparent;
	}
}
.Polaris-DataTable__Heading {
	.Polaris-DataTable__Icon {
		align-self: center;
	}
}

.Polaris-ResourceItem__ListItem {
	list-style-type: none;
}

.Polaris-Button--plain:focus:not(:active):not(.Polaris-Button--destructive) {
	color: var(--accent);

	.Polaris-Icon:not(.Polaris-Icon--isColored) svg {
		fill: var(--accent);
	}

	& > .Polaris-Button__Content::after {
		box-shadow: none;
		outline: 0.0625rem solid transparent;
	}
}

[data-has-navigation] .Polaris-TopBar-Search {
	min-width: 300px;
}

.Polaris-TopBar-Search__Results {
	& > .Polaris-LegacyCard {
		max-height: 60vh;
		overflow: auto;
	}
}

.Polaris-Button__Icon img {
	width: 20px;
	height: 20px;
	fill: #ffffff;
}

.Polaris-TextField--readOnly > .Polaris-TextField__Backdrop {
	background-color: var(--main2);
	opacity: 0.5;
}

.Polaris-FormLayout__Item > .Polaris-OptionList {
	padding: 0;
}
#root {
	.Polaris-Page-Header__BreadcrumbWrapper {
		align-items: center;
		display: flex;
	}
	.Polaris-IndexTable__IndexTableWrapper.Polaris-IndexTable__IndexTableWrapperWithBulkActions {
		.Polaris-IndexTable-ScrollContainer {
			padding-top: 36px;
		}
	}

	// .Polaris-IndexTable__Table.Polaris-IndexTable__Table--sticky {
	.Polaris-IndexTable__TableHeading.Polaris-IndexTable__TableHeading--first {
		.Polaris-IndexTable__ColumnHeaderCheckboxWrapper {
			padding-left: 1rem;
		}
	}
	// }

	.Polaris-Avatar__Initials {
		.Polaris-Avatar__Text {
			fill: #000000;
		}
	}

	.Polaris-Backdrop--belowNavigation {
		z-index: 90;
	}

	.Polaris-DatePicker__Title {
		text-transform: capitalize;
	}

	@media (min-width: 48.0625em) {
		.Polaris-Navigation__PrimaryNavigation {
			padding-top: 0;
		}
	}

	.Polaris-DropZone {
		transition: background-color 250ms;
		background-color: var(--secondary);

		&:hover {
			background-color: var(--tertiary);
		}

		.Polaris-DropZone-FileUpload__Action {
			--p-color-bg-interactive-subdued: var(--accent);
			--p-color-text-interactive: var(--textColorDark);
		}
	}

	// .Polaris-Button.Polaris-Button--primary {
	// 	--p-color-icon-on-color: var(--color-dark);
	// }
}

.Polaris-BulkActions__BulkActionButton {
	.Polaris-Button {
		background-color: var(--accent);
		color: #000000;
	}
}

.Polaris-Page {
	.Polaris-LegacyCard {
		// margin-top: 1rem; //why?
		background-color: var(--secondary);

		.Polaris-TextField__Backdrop {
			--p-color-bg: var(--primary);
		}
	}
}

.Polaris-TextField--readOnly {
	--p-color-border-interactive-focus: transparent;
}
.searchField {
	min-width: 200px;
}

// .Polaris-PositionedOverlay {
// .Polaris-Popover:not(.Polaris-Popover--fullWidth) {
// 	max-width: 350px; //huh?
// }

.Polaris-FormLayout__Item > .Polaris-OptionList {
	padding: 0;
}

.Polaris-Choice__Label {
	font-weight: 400;
	font-size: 14px;
}

.Polaris-Filters__FiltersWrapper,
.Polaris-Filters__Container {
	> .Polaris-Box {
		background: transparent;
	}

	border: none;
}

.Polaris-Modal-Section {
	& > section {
		padding: 0;
	}
}

.Polaris-OptionList-Option--select .Polaris-OptionList-Checkbox__Backdrop::before {
	background: $green;
}

.Polaris-OptionList-Option__Label {
	transition: background-color 150ms;
}
// Polaris-Button Polaris-Button--destructive Polaris-Button--disabled Polaris-Button--loading Polaris-Button--removeUnderline
.Polaris-Button.Polaris-Button--destructive {
	--p-color-bg-disabled: var(--red);
}

.Polaris-Tabs__Tab--active {
	.number_badge {
		background-color: var(--main1);
		color: var(--textColor);
	}
}

div[data-portal-id*="modal-:"] .Polaris-Modal__Body {
	padding: 1rem;
}

.Polaris-Tag .Polaris-Tag__TagText {
	color: var(--textColor);
}

//Fixes wierd 100% width on tabs with just icon
.Polaris-Tabs {
	.Polaris-Tabs__Tab--iconOnly {
		min-width: unset;
	}

	&.Polaris-Tabs--fitted {
		.Polaris-Tabs__Tab--iconOnly {
			width: 100%;
			min-width: unset;
		}

		.Polaris-Tabs__TabContainer {
			width: 100%;
		}

		.Polaris-Tabs__Tab {
			min-width: unset;
		}
	}
}

.Polaris-IndexTable__EmptySearchResultWrapper {
	img {
		max-width: 50vw;
		max-height: 50vh;
	}
}
.Polaris-Tabs__ActionListWrap {
	.Polaris-Text--root.Polaris-Text--subdued {
		font-size: 0.85em;
	}
}
